import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['chatInput', 'chatForm', 'submitBtn']

  static values  = {
    conversationId: Number,
    recieverId:     Number
  }

  connect() {
    console.log('Message form controller connected!');

    const chatInput      = this.chatInputTarget;
    const submitBtn      = this.submitBtnTarget;
    const chatForm       = this.chatFormTarget;
    const conversationId = this.conversationIdValue;
    const recieverId     = this.recieverIdValue;

    $(chatInput).focus();

    $(chatInput).on("keydown", async (event) => {
      if ((event.key === "Enter" || event.keyCode === 13) && !event.shiftKey) {
        event.preventDefault();
        $(chatForm).submit();
        handleFormSubmission();
        return false;
      }
    });

    $(submitBtn).click(function() {
      $(chatForm).submit();
      handleFormSubmission();
      return false;
    })

    function handleFormSubmission() {
      $("#proposal_text_area").val("");
      $("#message_body").val("");
      $("#message_body_with_attachment").val("");
      $(chatInput).focus();
    }

    var typingTimer;                //timer identifier
    var doneTypingInterval = 3000;  //time in ms, 3 seconds

    //on keyup, start the countdown
    $(chatInput).on('keyup', function () {
      clearTimeout(typingTimer);
      typingTimer = setTimeout(doneTyping, doneTypingInterval);
    });

    //on keydown, clear the countdown
    $(chatInput).on('keydown', function () {
      broadcastTyping(true);

      clearTimeout(typingTimer);
    });

    function doneTyping () {
      broadcastTyping(null);
    }

    function broadcastTyping(is_typing) {
      $.ajax({
        url: `/broadcast/typing/${conversationId}/${recieverId}`,
        method: "POST",
        data: { is_typing: is_typing }
      });
    }
  }
}
